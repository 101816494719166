<template>
  
    <v-col cols=12 :md="moreCols ? 12 : 9" class="pt-0"> 
      
      
       
        <v-data-table
          @click:row="onClickRow"
          :headers="moreCols ? moreHeaders : dafaultHeaders"
          loading-text="Завантаження..."
          no-data-text="Немає платежів для відображення"
          :items="pays"
          :options.sync="options"
          :page.sync="page"
          :server-items-length="paginations.count"
          :loading="loading"
          class="elevation-1 row-cursor"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          :footer-props="footer"
          focusable 
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-1" dark>
              <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''" @click="$emit('show-field-date')"><h2 class="mr-6 accent--text">Нова-Ком</h2></v-toolbar-title>
              
              <v-spacer/>
              <v-btn :icon="$vuetify.breakpoint.mobile" :outlined="!$vuetify.breakpoint.mobile" small :color="!inetPays ? 'accent' : 'grey'"
              :class="!inetPays ? 'primary--text' : ''"
              @click="inetPays = false, checkGetPays()"
              >
                 <v-icon v-if="$vuetify.breakpoint.mobile">mdi-television</v-icon> 
                 <span v-else>ТБ</span>
              </v-btn>
              <v-btn disabled :icon="$vuetify.breakpoint.mobile" :outlined="!$vuetify.breakpoint.mobile" small :color="inetPays ? 'accent' : 'grey'"
              :class="inetPays ? 'primary--text' : ''"
              class="ml-3"
              @click="inetPays = true, checkGetPays()"
              >
                <v-icon v-if="$vuetify.breakpoint.mobile">mdi-web</v-icon> 
                <span v-else>Інтернет</span>
              </v-btn>
              <v-spacer />
              
              <v-btn 
                :color="moreCols ? 'accent' : ''"
                title="Більше данних"
                icon
                @click="moreCols = !moreCols">
                  <v-icon>mdi-unfold-more-vertical</v-icon>
              </v-btn>

              
                
            </v-toolbar>
          </template>

          
          
          

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

          <template #item.index="{ item }"> 
            <span>{{ pays.indexOf(item) + paginations.start_index }}</span>
          </template>
          <template #item.date="{ item }">
            <v-tooltip bottom color="primary" :disabled="moreCols">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.date }}</span>
              </template>
              <span>Дата проведення платежу:<br>{{ item.date_pay }}</span>
            </v-tooltip>
          </template>
          <template #item.service="{ item }">
            <v-icon v-if="item && item.service == 1" color="green darken-2" >mdi-television</v-icon> 
            <v-icon v-else color="light-blue darken-2" >mdi-web</v-icon> 
          </template>
          <template #item.address="{ item }">
            <div href="#" v-if="true"  class="link_text" :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : ''" @click.prevent="openAbonentInfoModal(item)">{{ item && item.address }} <v-icon title="Видалений абонент" color="error" style="vertical-align: baseline;" small class="ml-1" v-if="item && item.is_removed">mdi-alert-circle</v-icon></div>
          </template>
          <template #item.operator="{ item }">
            <v-avatar
              v-if="!moreCols"
              :color="colorsDistributor[findDistributor(item.distributor, 'letter')]"
              size="28"
              :title="findDistributor(item.distributor, 'title')"
            >
              <span class="white--text text-subtitle-2">{{ findDistributor(item.distributor, 'short') }}</span>
            </v-avatar>
            <span v-else>{{ findDistributor(item.distributor, 'title') }}</span>
          </template>
          <template #item.amount="{ item }">
            <span :class="{'deep-orange--text': item.amount < 0}">{{ item.amount}}</span>
          </template>
          <template #item.type_pay="{ item }">
            <v-badge
              :value="item && item.filtersmanual"
              inline
              icon="mdi-hand-back-left"
              :title="item && item.manual && `Ручне проведення\nОбробив: ${item && item.user_worker}`"
            >{{ item && item.type_pay && pays_type.find(x => x.id == item.type_pay).name}}</v-badge>
             
          </template>
          <template #item.memo="{ item }">
            <span v-if="!moreCols" :title="item.memo">{{ item && item.memo && formatMemo(item.memo) }} </span>
            <span v-else>{{ item.memo }}</span>
          </template>

        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>
        </v-data-table>
      


    </v-col>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"



export default {
  name: 'nova-kom-table',
  components: {
  },
  props: ["user", "tabData"], 
  data: () => ({
    moreCols: false,
    filter_date: new Date().toDateInputValue(),
    updateTable: 0,
    pays: [],
    paginations: {},
    loading: false,
    options: {},
    page: 1,
    countFilters: 0,
    headers: [],
    inetPays: false,
    colorsDistributor:{
      "П": "yellow darken-3",
      "Ф": "deep-orange lighten-2",
      "Т": "primary lighten-2",
      "": ''
    },
    dafaultHeaders: [
      { text: '#', value: 'index', sortable: false,  width: 50},
      { text: 'Дата', value: 'date',  },
      { text: 'Пос.', value: 'service', sortable: false,  },
      { text: 'Абонент', value: 'address', sortable: false,  },
      { text: 'ПС.', value: 'operator',  sortable: false, },
      { text: "Оплата", value: 'amount', class: 'text-end', cellClass: 'text-end' },
      //{ text: 'Тип', value: 'type_pay', sortable: false, },
      { text: 'Примітки', value: 'memo', sortable: false, },
    ],
    moreHeaders: [
      { text: '#', value: 'index', sortable: false,  width: 50},
      { text: 'Дата', value: 'date',  },
      { text: 'Проведення', value: 'date_pay',  },
      { text: 'Пос.', value: 'service', sortable: false,  },
      { text: 'Абонент', value: 'address', sortable: false,  },
      { text: 'ПС.', value: 'operator',  sortable: false, },
      { text: "Оплата", value: 'amount', class: 'text-end', cellClass: 'text-end' },
      //{ text: 'Тип', value: 'type_pay', sortable: false, },
      { text: 'Примітки', value: 'memo', sortable: false, },
    ],
    headers_admin: [
      //
    ],
    footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
    },
      
  }),
  computed: {
      ...mapGetters({
          operators: "filters/operators",
          pays_type: "filters/pays_type"
      }),
      filters_NovakomPays: function () {
        return {
          date: new Date().toDateInputValue(),
          //month: new Date().toDateInputValue(),
          //distributor: ''
        }
      },
  },
  watch: {
    tabData: {
      handler () {
        this.pays = []
      },
      deep: true,
    },
    options: {
      handler (val, oldVal) {
        //console.log(this)
        //console.log("oldVal.page:", oldVal.page, oldVal, "val.page: ", val.page, val )
        oldVal.page && this.getPays();
        
      },
      deep: true,
    },
    updateTable(){
      this.updateTable && this.getPays();
    },
    '$parent.$refs.navbarTable.counterApplyFilterNovakom'(){
      this.checkGetPays()
    }
  },
  mounted (){
    
    //this.applyNavbarDate(true) 
  },
  activated() {
    //this.headers = moreCols && headersMore || defaultHeaders
    //this.viewTableHeaders()
    //this.applyNavbarDate()
  },
  deactivated(){
    //
  },
  methods: {
    onClickRow(){
      //
    },
    formatMemo(memo){
      if(memo.length > 9 ){
        return memo.substr(0, 9) + '...'
      }
      else return memo
    },
    findDistributor (distributor, method) {
      let find_distributor = this.operators.find(x => x.id == distributor)
      if(!find_distributor) return
      if(method == 'short') return find_distributor.short_name
      else if(method == 'title') return find_distributor.name
      else if(method == 'letter') return find_distributor.name[0]
    },
    viewTableHeaders(){
      this.headers = this[this.tabData?.headers || 'dafaultHeaders']
    },
    findFiltersNovakom(){
      let result = []
      if(this.$parent.$refs.navbarTable.novakomDateTypeMonth) {
        result.push({name: 'month', value: this.$parent.$refs.navbarTable.filter_novakom_month + '-01'})
      }
      else result.push({name: 'date', value: this.$parent.$refs.navbarTable.filter_novakom_date})
      
      result.push({name: 'distributor', value: this.$parent.$refs.navbarTable.filter_novakom_distributor || ''})
      
      return result
    },
    checkGetPays(){
      this.$parent.$refs.totalPays.getTotalPays(this.findFiltersNovakom(false), this.inetPays)
      //console.log("checkGetPays")
      if(this.options.page == 1){
        this.getPays();
      }else{
        this.page = 1;
      }
    },
    filterFormat() {
      this.countFilters = 0
      const temp = {};
      temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
      temp.itemsPerPage = this.options.itemsPerPage;
      temp.page = this.options.page;

      /* Збір фільтрів */
      this.findFiltersNovakom().forEach(filter => temp[filter.name] = filter.value + '');
      /* ------------- */
      
      return temp; 
    },
    getPays(){
      //console.log("getPays()")
      this.selectedTableRows = []
      this.loading = true;
      
      if(this.inetPays){
          axios({
              method: "get",
              url: this.$router.app.devUrlBillingAPI,
              params: {req_cmd: "getPayments", ...this.filterFormat() },
              
          })
          .then( response =>{
            //console.log(response.data)
            if(response.data.resp_status == "OK"){
              this.pays = response.data.resp_result.result
              //this.paginations.count = response.data.resp_result.length
              this.paginations = response.data.resp_result.paginations
            }else{
              this.pays = []
              this.paginations = {}
            }
          })
          .catch(() => {})
          .finally(() => ( this.loading = false )) 
        }else{
          axios.get(`/api/cabletv/pays_nova/`, {params: this.filterFormat()})
          .then( response =>{
            //console.log(response.data)
            this.pays = response.data.results
            this.paginations = response.data.paginations
            //this.$parent.$refs.totalPays.total = response.data.total
            //console.log(this.$parent.$refs.totalPays.total)
          })
          .catch(err => {
            this.$router.app.$sheet.show("Помилка!", err);
          })
          .finally(() => (this.loading = false));
        }
    },
    
  }
  
}
</script>
<style scoped>
.link_text{
  text-decoration: none;
  cursor: pointer;
  color: #1565C0
}
</style>