<template>
        
        <v-dialog v-model="dialog" persistent :max-width="messageDone ? 500 : 400">
            
            <v-card>
            <v-card-title class="d-flex justify-space-between">
            <span class="headline " v-if="!messageDone">Завантаження файлу банку </span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container v-if="messageDone">
                <v-row justify="center">
                    <v-icon x-large color="green" class="mt-2 mb-3">mdi-check</v-icon>
                </v-row>
                <div class="text-center mb-6"><h2>Завантажено!</h2></div>
                <div style="white-space: pre-wrap;">{{ messageDone }}</div>
                <div v-if="pays_undefined && pays_undefined.length > 0" style="white-space: pre-wrap;">
                <v-expansion-panels focusable class="mt-4">
                    <v-expansion-panel>
                        <v-expansion-panel-header>Неопізнані оплати ({{  pays_undefined.length  }})</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <span v-for="(item,i) in pays_undefined"
                            :key="i">
                                {{ i+1 }}. {{ item }}<br><br>
                            
                            </span>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                </div>

            </v-container>
            <v-container :class="{'d-none': messageDone}">
                
                
                    <v-row>       
                        <v-col
                            justify="center"
                            class="mt-6 text-center"
                            cols="12" 
                            >
                            <v-form 
                                ref="form">
                            <v-file-input
                                id="myFile"
                                accept=".csv"
                                truncate-length="20"
                                label="Виписка банку"
                            ></v-file-input>
                        </v-form>
                        </v-col>
                    </v-row>
                
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn v-if="!messageDone" :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="uploadFile()">Зберегти</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    export default {
        name: 'upload-file-bank-modal',
        data: () => ({
            loadingSubmit:false,
            dialog: false,
            messageDone: '',
            pays_undefined: []
            
        }),
        mounted(){

        },
        methods: {
            show(){
                this.dialog = true
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                //this.reset()
                this.dialog = false
                this.messageDone = ''
                this.pays_undefined = []
            },
            async uploadFile(){
                this.loadingSubmit = true
                const inputElement = document.getElementById('myFile')
                const file = inputElement.files[0]
                if (!file) {
                    this.loadingSubmit = false
                    this.$router.app.$sheet.show("Файл не вибрано!")
                    return
                }

                const body = new FormData()
                body.append('file', file)
                //body.append('user_id', this.user.id)
                try {
                    const { data } = await axios({
                        url: '/api/report/upload_bank/',
                        method: 'POST', 
                        data: body
                    })
                    if(data){
                        this.loadingSubmit = false
                        this.$router.app.$snack.show("Файл банку завантажено!")
                        this.messageDone = data.message
                        this.pays_undefined = data.pays_undefined
                    }else{
                        this.$router.app.$sheet.show("Помилка!")
                    }
                } catch (err) {
                    this.loadingSubmit = false
                    this.$router.app.$sheet.show("Помилка!", err)
                    
                }
            },
            
        }
    }
</script>