<template>
  
    <v-col cols=12 :md="moreCols ? 12 : 9" class="pt-0"> 
        
      <keep-alive>
        <Filters ref="filtersNovaKomBank" v-on:apply-filters="checkGetPays(), $parent.$refs.navbarTable.filter_bank_date = ''" for="novakom_bank" />
      </keep-alive>
      
       
        <v-data-table
          @click:row="onClickRow"
          :headers="dafaultHeaders"
          loading-text="Завантаження..."
          no-data-text="Немає платежів для відображення"
          :items="pays"
          :options.sync="options"
          :page.sync="page"
          :server-items-length="paginations.count"
          :loading="loading"
          class="elevation-1 row-cursor"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          :footer-props="footer"
          focusable 
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-1" dark>
              <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''" @click="$emit('show-field-date')"><h2 class="mr-6 accent--text">Банк</h2></v-toolbar-title>
              
              
              <v-spacer />

              <v-btn 
                title="Завантажити файл"
                icon 
                @click="$refs.uploadFileNovakom.show()">
                  <v-icon>mdi-cloud-download</v-icon>
              </v-btn>

              <v-btn 
                title="Поновити данні"
                icon 
                :loading="loading"
                @click="getPays()">
                  <v-icon>autorenew</v-icon>
              </v-btn>
                
              <v-btn  
                title="Фільтри пошуку" 
                icon 
                :disabled="loading"
                @click="$refs.filtersNovaKomBank.show()"
              >
                <v-badge
                :value="countFilters > 0"
                color="accent"
                text="primary"
                :content="countFilters"
                dark
              >
                <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
                
            </v-toolbar>
          </template>

          <template
            v-if="countFilters > 0"
            v-slot:header="{ props: { headers } }"
          >
            <thead>
              <tr>
                <th :colspan="headers.length">
                  Фільтри: 
                  <keep-alive>
                    <FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :is_close="true" v-on:open-filters="$refs.filtersNovaKomBank.show()"/>
                  </keep-alive>
                </th>
              </tr>
            </thead>
          </template> 
          
          

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        
        <template #item.is_recived="{ value }"> 
            <v-icon v-if="value" color="green">mdi-check</v-icon>
        </template>

        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>
        </v-data-table>
      
        <keep-alive>
            <UploadFileNovakomModal ref="uploadFileNovakom" />
        </keep-alive>
        
    </v-col>
</template>

<script>
import axios from "axios"
import Filters from '@/components/Abonents/Filters.vue'
import FiltersChips from '@/components/Abonents/FiltersChips.vue'
import UploadFileNovakomModal from '@/components/Pays/UploadFileNovakomModal.vue'



export default {
  name: 'novakom-banks-table',
  components: {
    Filters,
    FiltersChips,
    UploadFileNovakomModal
  },
  props: ["user", "tabData"], 
  data: () => ({
    moreCols: false,
    filter_date: new Date().toDateInputValue(),
    updateTable: 0,
    pays: [],
    paginations: {},
    loading: false,
    options: {},
    page: 1,
    countFilters: 0,
    headers: [],
    dafaultHeaders: [
      { text: 'Дата', value: 'date',  },
      { text: 'Дата Банк', value: 'date_bank'  },
      { text: 'Банк', value: 'bank' },
      { text: 'Сервіс', value: 'service', },
      { text: "Статус", value: 'is_recived',  },
      { text: 'Сума', value: 'amount', sortable: false, class: 'text-end', cellClass: 'text-end' },
      { text: 'Комісія', value: 'comission',  sortable: false, class: 'text-end', cellClass: 'text-end' },
      { text: 'Всього', value: 'total',  sortable: false, class: 'text-end', cellClass: 'text-end' },
      
    ],
    headers_admin: [
      //
    ],
    footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
    },
      
  }),
  computed: {
      
    filters_NovakomBankPays: function () {
        return {
          date: new Date().toDateInputValue(),
        }
      },
  },
  watch: {
    tabData: {
      handler () {
        this.pays = []
        this.clearFiltersSettings()
        this.filtersSettings()
        //this.checkGetJob()
      },
      deep: true,
    },
    options: {
      handler (val, oldVal) {
        //console.log(this)
        //console.log("oldVal.page:", oldVal.page, oldVal, "val.page: ", val.page, val )
        oldVal.page && this.getPays();
        
      },
      deep: true,
    },
    updateTable(){
      this.updateTable && this.getPays();
    },
    '$parent.$refs.navbarTable.counterApplyDateBank'(){
      this.applyNavbarDate(true) 
    }
  },
  mounted (){
    //
  },
  activated() {
    this.getPays()
  },
  deactivated(){
    //
  },
  methods: {
    onClickRow(){
      //
    },
    
    applyNavbarDate(request=false){
      this.$refs.filtersNovaKomBank.filters.find(x => x.name == "start_date").value = this.$parent.$refs.navbarTable.filter_bank_date
      this.$refs.filtersNovaKomBank.filters.find(x => x.name == "end_date").value = this.$parent.$refs.navbarTable.filter_bank_date
      request && this.checkGetPays()
    },
    applyFiltersForTab(){
      for (let filter in this[this.tabData.filters]) {
        this.$parent.$refs.navbarTable.filter_bank_date = new Date().toDateInputValue()
        this.$refs.filtersNovaKomBank.filters.find(x => x.name == filter).value = this[this.tabData.filters][filter]
        //this.$refs.filtersNovaKomBank.filters.find(x => x.name == filter).disable = true // вимкнути вибір фільтру
      }
      this.checkGetPays()
      return true
    },
    clearFiltersSettings(){
      this.$refs.filtersNovaKomBank.filters.forEach(element => { element.value = "", element.disable = false });
    },
    filtersSettings(){
      this.tabData && this.tabData.filters && this.applyFiltersForTab() || this.checkGetPays()
    },
    checkGetPays(){
      //this.$parent.$refs.totalPays.getTotalPays(this.$refs.filtersNovaKomBank.filters, this.inetPays)
      //console.log("checkGetPays")
      if(this.options.page == 1){
        this.getPays();
      }else{
        this.page = 1;
      }
    },
    clearFilter(filter_name){
      this.$refs.filtersNovaKomBank.filters.find(x => x.name == filter_name).value = ''
      this.$refs.filtersNovaKomBank.apply()
    },
    getFiltersOfComponents(){
      return this.$refs.filtersNovaKomBank.filters.filter(x => x.value && x.value != '') 
    },
    filterFormat() {
      this.countFilters = 0
      const temp = {};
      temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
      temp.itemsPerPage = this.options.itemsPerPage;
      temp.page = this.options.page;
      
      //if(this.search) temp.search = this.search
      
      /* получение значений фильтрации и просчет колличества фильтров */
      this.filtersComponents = this.getFiltersOfComponents()
      this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
      this.countFilters = this.filtersComponents.length
      
      return temp; 
    },
    getPays(){
      //console.log("getPays()")
      this.selectedTableRows = []
      this.loading = true;
      this.$parent.$refs.navbarTable.disabled_date_field = true; // отключаем применение даты до окончания текущего запроса
      
          axios.get(`/api/report/get_bank_nova/`, {params: this.filterFormat()})
          .then( response =>{
            //console.log(response.data)
            this.pays = response.data.results
            this.paginations = response.data.paginations
            this.$store.commit('novakom_bank_total/updateTotal', response.data.extra_param)
            //this.$parent.$refs.totalPays.total = response.data.total
            //console.log(this.$parent.$refs.totalPays.total)
          })
          .catch(() => {
            //console.log(error);
            //this.errored = true;
          })
          .finally(() => (this.loading = false, this.$parent.$refs.navbarTable.disabled_date_field = false));
        
    },
  }
  
}
</script>
<style scoped>
.link_text{
  text-decoration: none;
  cursor: pointer;
  color: #1565C0
}
</style>