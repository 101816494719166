<template>
  <v-container :style="{'max-width': '1500px'}">
    <!--<PaysNavbar ref="navbarPays" :user="user" v-on:tab-data="changeTabData" v-on:view-tab="viewTab"/>-->
    <TableNavbar ref="navbarTable" :user="user" v-on:tab-data="changeTabData" v-on:view-tab="viewTab" menu="paysMenu" :showFieldDate="showFieldDate"/>
    <v-row justify="center" class="mt-0 mb-0">
      
      <transition name="page" mode="out-in">
        <keep-alive>
          <component :user="user" :tabData="tabData" :is="is_component" v-on:show-field-date="showFieldDate = !showFieldDate"></component>
        </keep-alive>
      </transition>
      
      <transition name="page" mode="out-in">
        <keep-alive>
          <PaysTotal ref="totalPays" v-if="is_component == 'PaysTable'"/> 
          <NovaKomTotal ref="totalPays" v-else-if="is_component == 'NovaKomTable'"/> 
          <NovaKomBanksTotal ref="novaKomBanksTotal" v-else-if="is_component == 'NovaKomBanksTable'"/> 
        </keep-alive>
      </transition>
      
    </v-row>


    
    
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import PaysTable from '@/components/Pays/PaysTable.vue'
import NovaKomTable from '@/components/Pays/NovaKomTable.vue'
//import PaysNavbar from '@/components/Pays/PaysNavbar.vue'
import TableNavbar from '@/components/Abonents/TableNavbar.vue'
import PaysTotal from '@/components/Pays/PaysTotal.vue'
import NovaKomTotal from '@/components/Pays/NovaKomTotal.vue'
import PaysTabStatistic from '@/components/Pays/PaysTabStatistic.vue'
import NovaKomBanksTable from '@/components/Pays/NovaKomBanksTable.vue'
import NovaKomBanksTotal from '@/components/Pays/NovaKomBanksTotal.vue'




export default {
  name: 'view-pays',
  components: {
    PaysTable,
    NovaKomTable,
    TableNavbar,
    //PaysNavbar,
    PaysTabStatistic,
    PaysTotal,
    NovaKomTotal,
    NovaKomBanksTable,
    NovaKomBanksTotal
  },
  data: () => ({
    is_component: 'PaysTable',
    tabData: {},
    showFieldDate: false
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    viewTab(newTab){
      //console.log(newTab)
      this.is_component = newTab
    },
    changeTabData(newTabData){
      this.tabData = newTabData
    }
  }
}
</script>



