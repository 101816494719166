<template>
  
  <v-col  cols=12 md=3 sm=6 class="pt-0">
    <v-card
    class="mx-auto"
  >
    <v-toolbar
      flat 
      color="secondary lighten-1"
      dark
    >
      <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"><h3 class="mr-6 accent--text">Всього</h3></v-toolbar-title>
      <v-spacer /> 
    </v-toolbar>

    <v-list v-if="total" subheader dense >
      
      <v-subheader>ІНТЕРНЕТ</v-subheader>
      <v-list-item
        v-for="(k) in itemsListInet"
        :key="k.name"
        
      >
        <v-list-item-content>
          <v-list-item-title v-text="k.title +':'"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title class="text-right">{{ total[k.name] }}</v-list-item-title>
        </v-list-item-content>   
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader>ТЕЛЕБАЧЕННЯ</v-subheader>
      <v-list-item
        v-for="(k) in itemsListTv"
        :key="k.name"
        
      >
        <v-list-item-content>
          <v-list-item-title v-text="k.title +':'"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title class="text-right">{{ total[k.name] }}</v-list-item-title>
        </v-list-item-content>   
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader >ТРК</v-subheader>
      <v-list-item
        v-for="(k) in itemsListTrk"
        :key="k.name"
        
      >
        <v-list-item-content>
          <v-list-item-title v-text="k.title +':'"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title class="text-right">{{ total[k.name] }}</v-list-item-title>
        </v-list-item-content>   
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader ></v-subheader>
      <v-list-item
        v-for="(k) in itemsListOther"
        :key="k.name"
        
      >
        <v-list-item-content>
          <v-list-item-title v-text="k.title +':'"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title class="text-right">{{ total[k.name] }}</v-list-item-title>
        </v-list-item-content>   
      </v-list-item>
    </v-list>

    
    
  </v-card>
  </v-col>
  

  
</template>
<script>
import { mapGetters } from "vuex"
export default {
    name: 'novakom-banks-total',
    data: () => ({
      itemsListInet: [
        {name: 'bank_inet', title: 'Сума'},
        {name: 'comission_inet', title: 'Комісія'},
        {name: 'total_inet', title: 'Всього'},
        
      ],
      itemsListTv: [
        {name: 'bank_tv', title: 'Сума'},
        {name: 'comission_tv', title: 'Комісія'},
        {name: 'total_tv', title: 'Всього'},
      ],
      itemsListTrk: [
        {name: 'bank_inet_trk', title: 'Інтернет'},
        {name: 'bank_tv_trk', title: 'Телебачення'},
      ],
      itemsListOther: [
        {name: 'total_bank', title: 'Всього по виписці'},
        {name: 'not_recive', title: 'Не надійшли'},
      ]
    }),
    computed: {
      ...mapGetters({
        total: "novakom_bank_total/total",
      }),
    },
    watch: {
      //
    },
    methods: {
      
    }
}
</script>
