<template>
    
    
  <transition name="page" mode="out-in">
    <v-row v-if="$route.name == 'job' || $route.name == 'pays'" justify="center" class="mt-0 mb-1">
      <v-col cols="12" :lg="is_novakom ? 6 : 7" :class="$vuetify.breakpoint.smAndDown ? 'px-0 pt-1' : ''">
        <v-tabs
            v-model="tabsNow"
            background-color="background" 
            center-active
            show-arrows
            centered
            
          >
            <v-tab
              v-for="i in nowMenu[currentMenu]"
              :title="i.details"
              :key="i.key"
              @click="showComponent(i)"
            >
              <v-badge
              color="primary lighten-1"
                :value="tabsNow == nowMenu[currentMenu].indexOf(i) && badgeCount"
                :content="badgeCount"
              >
                <span class="font-weight-bold">{{ i.title }}</span>
              </v-badge>
            </v-tab>
          </v-tabs>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp || showFieldDate || is_tab_accept_altair" cols="12" :md="is_novakom  ? 8 : 4" :lg="is_novakom  ? 5 : 3" class="d-md-inline-flex justify-center">
        
        
        <!----------------------------------------------  Нова-Ком --------------------------------------------------->
        
        <v-col md="6" class="py-0 px-0" v-if="is_novakom && novakomDateTypeMonth">
          <v-text-field 
            ref="input_novakom_month"
            v-model="filter_novakom_month"
            dense
            append-icon="mdi-magnify"
            prepend-icon="mdi-calendar-blank"
            label="Місяць"
            type="month"
            class="mr-md-3"
            outlined
            hide-details
            @click:append="counterApplyFilterNovakom += 1"
            @click:prepend="novakomDateTypeMonth = false"
            >
          </v-text-field>
        </v-col>
        <v-col md="6" class="py-0 px-0" v-if="is_novakom && !novakomDateTypeMonth">
          <v-text-field 
          
            ref="input_novakom_date"
            v-model="filter_novakom_date"
            dense
            append-icon="mdi-magnify"
            prepend-icon="mdi-calendar-today"
            label="День"
            type="date"
            class="mr-md-3"
            outlined
            hide-details
            @click:append="counterApplyFilterNovakom += 1"
            @click:prepend="novakomDateTypeMonth = true"
          >
          </v-text-field>
        </v-col>
        <v-col md="6" class="py-0 px-0" v-if="is_novakom">
          <v-select 
            clearable
            menu-props="auto"
            ref="select_distributors"
            v-model="filter_novakom_distributor"
            :items="distributorsList"
            item-text="name"
            item-value="id"
            label="Постачальник"
            :class="{'mt-2':$vuetify.breakpoint.smAndDown}"
            @change="counterApplyFilterNovakom += 1"
            no-data-text="Немає данних"
            dense
            outlined
            hide-details
            >
          </v-select>
        </v-col>
       <!--------------------------------------------- / Нова-Ком --------------------------------------------------->

       <!---------------------------------------------  Банк --------------------------------------------------->

       
        <v-text-field 
        v-if="is_novakom_bank"
          prepend-icon="mdi-chevron-left"
          append-outer-icon="mdi-chevron-right"
          ref="input_date"
          v-model="filter_bank_date"
          :disabled=" disabled_date_field"
          class=""
          dense
          append-icon="mdi-magnify"
          label="Дата"
          type="date"
          outlined
          hide-details
          @click:append="counterApplyDateBank += 1"
          @click:prepend="dateBankChange()"
          @click:append-outer="dateBankChange(true)"
        >
        </v-text-field>
        
        
        <!--<v-col md="6" class="py-0 px-0" v-if="is_novakom_bank">
          <v-select 
            clearable
            menu-props="auto"
            ref="select_distributors"
            v-model="filter_bank_banks"
            :items="banksList"
            item-text="name"
            item-value="id"
            label="Банк"
            :class="{'mt-2':$vuetify.breakpoint.smAndDown}"
            @change="counterApplyFilterNovakomBank += 1"
            no-data-text="Немає данних"
            dense
            outlined
            hide-details
            >
          </v-select>
        </v-col>-->
        
       <!---------------------------------------------  / Банк --------------------------------------------------->

        <v-text-field 
        v-else
        prepend-icon="mdi-chevron-left"
        append-outer-icon="mdi-chevron-right"
        :class="{'d-none': is_tab_accept_altair || is_tab_pon_altair || is_novakom || is_novakom_bank}"
        ref="input_date"
        v-model="filter_date"
        :disabled="tabsNow != 0 || disabled_date_field"
        dense
        append-icon="mdi-magnify"
        label="Дата"
        type="date"
        outlined
        hide-details
        @click:append="counterApplyDate += 1"
        @click:prepend="dateChange()"
        @click:append-outer="dateChange(true)"
        >
        </v-text-field>
        

        
        
        <v-select 
            v-if="is_tab_accept_altair"
            menu-props="auto"
            ref="select_operator"
            v-model="filterOperator"
            :items="operatorsList"
            item-text="name"
            item-value="id"
            label="Оператор"
            no-data-text="Немає данних"
            dense
            outlined
            clearable
            hide-details
          >
          </v-select>
          <v-select 
            v-if="is_tab_pon_altair"
            menu-props="auto"
            ref="select_operator"
            v-model="filterOperatorPon"
            :items="operatorsList"
            item-text="name"
            item-value="id"
            label="Оператор"
            no-data-text="Немає данних"
            dense
            outlined
            clearable
            hide-details
          >
          </v-select>
      </v-col>
    </v-row>
  </transition>
</template>

<script>
import { mapGetters } from "vuex"
export default {
    name: "table-navbar",
    props: ["user", "menu", "showFieldDate"],
    data: () => ({
      typeInputDate: '',
      listActualOperatorsGroup: [],
      listIdOperatorsGroup: [1,2,6,12,16],
      filterOperator: [],
      filterOperatorPon: [],
      banksList: [],
      operatorsList: [],
      distributorsList: [],
      is_tab_accept_altair: false,
      is_tab_pon_altair: false,
      tabsNow:[],
      badgeCount: 0,
      disabled_date_field: false,
      counterApplyDate: 0,
      counterApplyDateBank: 0,
      counterApplyOperator: 0,
      counterApplyOperatorPon: 0,
      counterApplyFilterNovakom: 0,
      filter_bank_date: new Date().toDateInputValue(),
      filter_bank_banks: null,
      filter_novakom_date: new Date().toDateInputValue(),
      filter_novakom_month: new Date().toISOString().slice(0, 7),
      filter_novakom_distributor: null,
      filter_date: '', //new Date().toDateInputValue(),
      is_component: '',
      novakomDateTypeMonth: false,
      is_novakom: false,
      is_novakom_bank: false,
      currentMenu: 1,
      nowMenu: [],
      menuDict:{
        jobMenu:{
            1: [
                {title: "Заявки", component: "JobTable", key: 1001, details: "Всі заявки з можливістю використовувати фільтри (фільтр за замовчуванням: заявки на поточний день )", data: {filters: "filters_allJob"}},
                {title: "Обробка", component: "JobTable", key: 1002, details: "Завяки, що потребують перевірки та обробки оператором", data: {report: false, filters: "filters_preJob"}},
                {title: "Нерозподілені", component: "JobTable", details: "Заявки на поточний день, нерозподілені серед монтерів", key: 1003, data: {report: false, filters: "filters_newJob"}},
                {title: "У роботі", component: "JobTable", details: "Заявки на поточний день які знаходяться у процессі виконання", key: 1004, data: {report: false, filters: "filters_nowJob"}},
                {title: "PON", component: "JobTable", details: "Черга на PON в очікуванні черги", key: 1005, data: {report: false, filters: "filters_ponJob"}},
                //{title: "Статистика", component: "JobTabStatistic", details: "", key: 1006},
            ],
            2: [ //Монтер
                {title: "Заявки", component: "JobTable", key: 1011, details: "Всі заявки з можливістю використовувати фільтри (фільтр за замовчуванням: заявки на поточний день )",  data: {report: false, filters: "filters_allJob",}},
                {title: "Розподіл", component: "JobTable", key: 1012, details: "Заявки на поточний день, нерозподілені серед монтерів", data: {report: false, accept: true, headers: 'headers_monter_accept', filters: "filters_newJob"}},
                {title: "Група", component: "JobTable", details: "Заявки на поточний день, розподілені на монтера або за його участю", key: 1013, data: {report: false, headers: "headers_monter_group", filters: "filters_monter_plus_worker", section: 'group'}},
                {title: "Мої", component: "JobTable", details: "", key: 1014, data: {report: true, headers: 'headers_monter_report', filters: "filters_monter_report"}},
              ]
        },
        paysMenu:{
            1: [
                {title: "Платежі", component: "PaysTable", key: 10021, data: {filters: "filters_allPays"}},
                {title: "Нова-Ком", component: "NovaKomTable", key: 10022, data: {filters: "filters_NovakomPays"}},
                //{title: "Статистика", component: "PaysTabStatistic", key: 1004},
            ],
            2: [
                {title: "Платежі", component: "PaysTable", key: 10021, data: {filters: "filters_allPays"}},
                {title: "Нова-Ком", component: "NovaKomTable", key: 10022, data: {filters: "filters_NovakomPays"}},
                {title: "Нова-Ком Банк", component: "NovaKomBanksTable", key: 10023, data: {filters: "filters_NovakomBankPays"}},
                //{title: "Статистика", component: "PaysTabStatistic", key: 1004},
            ],
        },
        operatorsGroup:{
          
        }
      }
    }),
    computed:{
      ...mapGetters({
          operators_groups: "filters/operators_groups",
          distributors: "filters/operators",
      }),
    },
    mounted(){
      this.createFilters()
      this.nowMenu = this.menuDict[this.menu]
      this.badgeCount = 0
      //console.log(this.menu)
      
      if(this.menu == 'jobMenu'){
        this.currentMenu = this.user?.user_type == 2 && 2 || 1
      }
      if(this.menu == 'paysMenu'){
        this.currentMenu = this.user?.operator_id == 1 && 2 || 1
      }
      //console.log(this.nowMenu[this.currentMenu])
      this.showComponent(this.nowMenu[this.currentMenu][0])     // Имитируем нажатие первой вкладки
    },
    watch:{
      filter_date(oldVal){
        if(oldVal && (this.$vuetify.breakpoint.mdAndUp || this.showFieldDate) && this.$refs.input_date.isFocused) this.counterApplyDate += 1
      },
      filter_bank_date(oldVal){
        if(oldVal && (this.$vuetify.breakpoint.mdAndUp || this.showFieldDate) && this.$refs.input_date.isFocused) this.counterApplyDateBank += 1
      },
      filterOperator(){
        this.counterApplyOperator += 1
      },
      filterOperatorPon(){
        if(this.is_tab_pon_altair) this.counterApplyOperatorPon += 1
      },
      tabsNow(){
        
        this.is_tab_accept_altair = this.user?.operator_id == 1 && this.menu == 'jobMenu' && this.currentMenu == 2 && (this.tabsNow == 1 || this.tabsNow == 2)
        this.is_tab_pon_altair = this.user?.operator_id == 1 && this.menu == 'jobMenu' && this.currentMenu == 1 && this.tabsNow == 4
        if(!this.is_tab_pon_altair) this.filterOperatorPon = []
      },
      novakomDateTypeMonth(val){
        if(val) this.filter_novakom_month = this.filter_novakom_date && this.filter_novakom_date.slice(0, 7) || new Date().toDateInputValue().slice(0, 7)
        else this.filter_novakom_date = this.filter_novakom_month && this.filter_novakom_month + '-01' || new Date().toDateInputValue()
      }
    },
    activated() {
      //
    },
    deactivated(){
      //
    },
    methods:{
      showComponent(component){
        this.badgeCount = 0
        //console.log(component, this.nowMenu, this.tabsNow)
        
        this.is_novakom = component.component == 'NovaKomTable'
        this.is_novakom_bank = component.component == 'NovaKomBanksTable'

        this.$emit('view-tab', component.component)
        this.$emit('tab-data', component.data)
      },
      dateBankChange(plus){
        const date = new Date(this.filter_bank_date || new Date().toDateInputValue());
        if(plus) date.setDate(date.getDate() + 1);
        else date.setDate(date.getDate() - 1);
        this.filter_bank_date = date.toISOString().split('T')[0]
        this.counterApplyDateBank += 1
      },
      dateChange(plus){
        const date = new Date(this.filter_date || new Date().toDateInputValue());
        if(plus) date.setDate(date.getDate() + 1);
        else date.setDate(date.getDate() - 1);
        this.filter_date = date.toISOString().split('T')[0]
        this.counterApplyDate += 1
      },
      joinDistributor() {
        let operator_group = this.operators_groups.find(x => x.id == this.user.operator_id)
        if(!operator_group) return []
        return operator_group.distributor
      },
      createFilters(){
        if(this.user?.operator_id == 1){ // Якщо оператор "Altair Group"
          this.operatorsList = this.findOperatorsGroup()
          this.distributorsList = this.distributors
        } else this.distributorsList = this.joinDistributor()
        //this.filter_novakom_distributor = this.distributorsList[0]?.id
      },
      findOperatorsGroup(){
        let tempGroup = []
        this.listIdOperatorsGroup.forEach(elem => {
          tempGroup.push(this.operators_groups.find(x => x.id == elem ))
        })
        return tempGroup
      }
    }
}
</script>
